<script setup lang="ts">
withDefaults(
	defineProps<{
		hideCounter?: boolean;
		type?: string;
		active?: boolean;
		hideShadow?: boolean;
	}>(),
	{
		hideCounter: false,
		type: "rookie",
		active: false,
		hideShadow: true
	}
);

const imgConfig = {
	sector: {
		img: "sectors"
	},
	border: {
		img: "borders"
	},
	button: {
		img: "buttons"
	},
	arrow: {
		img: "arrows"
	}
};
</script>

<template>
	<div class="spinner-wheel" :class="{ animated: active, shadow: !hideShadow }">
		<NuxtImg
			v-for="(element, key) in imgConfig"
			:key="key"
			:src="`/nuxt-img/wheel/${type}/${element.img}.png`"
			format="webp"
			:class="key"
			alt="Wheel element"
		/>
		<AText v-if="!hideCounter" class="text-carabanchel text-tlalnepantla fortune-wheel-label" :modifiers="['bold']"
			>1</AText
		>
	</div>
</template>

<style lang="scss" scoped>
.spinner-wheel {
	display: flex;
	position: relative;
	width: 100px;
	height: 100px;
	border-radius: 8px 0 0 8px;
	cursor: pointer;

	&.shadow {
		box-shadow: 0 0 8px #fff490;
		border-radius: 50%;
	}

	&.animated {
		& .sector {
			animation: animation__sector 16.5s infinite;
		}

		& .button {
			animation: animation__button 16.5s infinite linear;
		}
	}

	& .sector {
		position: absolute;
		right: 1px;
		top: 1px;
		width: 100px;
	}

	& .border {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100px;
	}

	& .arrow {
		position: absolute;
		right: 38%;
		top: -6px;
		width: 24px;
	}

	& .button {
		position: absolute;
		right: 40%;
		top: 40%;
		width: 20px;
	}

	.fortune-wheel-label {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--сirebon);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 5%;
		left: 10%;
	}
}

@keyframes animation__sector {
	0%,
	100% {
		transform: rotate(0);
	}
	34% {
		transform: rotate(730deg);
	}
	39.3939%,
	99.9999% {
		transform: rotate(720deg);
	}
}
@keyframes animation__button {
	0%,
	10%,
	20%,
	30%,
	39.3939%,
	100% {
		transform: scale(1);
	}
	5%,
	15%,
	25%,
	35% {
		transform: scale(1.2);
	}
}
</style>
